<template>
  <!-- prettier-ignore -->
  <NBreadcrumb>
    <template v-if="isDashboard">
      <NBreadcrumbItem class="!font-bold breadcrumb-dashboard">
        <home-filled class="!align-middle opacity-90 mb-1 mr-1" /> {{ $t(dashboard.name) }}
      </NBreadcrumbItem>
    </template>
    <template v-else>
      <NBreadcrumbItem class="breadcrumb-level-1 hidden sm:inline-block">
        <i :class="curMenu.firstLevel.icon" class="mr-1" /> {{ $t(curMenu.firstLevel.name) }}
      </NBreadcrumbItem>
      <NBreadcrumbItem v-if="curMenu.isSub" class="!font-bold !cursor-pointer breadcrumb-level-2" @click="routerTo(curMenu.parent.path)">
        {{ $t(curMenu.parent.name) }}
      </NBreadcrumbItem>
      <NBreadcrumbItem class="!font-bold breadcrumb-level-3">{{ $t(curMenu.name) }}</NBreadcrumbItem>
    </template>
  </NBreadcrumb>
</template>

<script setup>
import { computed } from 'vue';
import menus from '@admin/menus';
import dashboard from '@admin/menus/dashboard';
import { useMenus, useRouter } from '@admin/hooks';

const { flatMenus } = useMenus(menus);

const { router, routerTo } = useRouter();

const curMenu = computed(() => flatMenus.value?.find(o => o.name === router.currentRoute.value.name) ?? dashboard);

const isDashboard = computed(() => curMenu.value.name === dashboard.name);
</script>
