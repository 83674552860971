<template>
  <NSelect class="n-role" v-bind="attrs" />
</template>

<script setup>
import { computed } from 'vue';
import { useBootstrap } from '@admin/hooks';

const { roles } = useBootstrap();

const attrs = computed(() => ({
  options: roles.value.map(o => ({ value: o.value, label: o.label })),
  label: 'role',
}));
</script>
