<template>
  <NSelect class="n-fx-rate-template" v-bind="attrs" />
</template>

<script setup>
import { BYTE } from '@/constant';
import { computed, ref, watch } from 'vue';
import { loadAll } from '@admin/api/cash/fx-rate-template';

const props = defineProps({
  label: String,
  name: String,
  itId: [Number, String],
});

const options = ref([]);

const attrs = computed(() => ({
  label: props.label ?? 'fxRateTemplate',
  options: options.value,
}));

const fetchOptions = itId => {
  return loadAll({ itId }).then(r => {
    options.value = r?.map(o => ({ label: `${o.name} (${o.id})`, value: o.id }));
  });
};

watch(
  () => props.itId,
  to => {
    if ([undefined, ''].includes(to)) return fetchOptions();
    if (to >= BYTE.min && to <= BYTE.max) return fetchOptions(to);
    options.value = [];
  },
  { immediate: true },
);
</script>
