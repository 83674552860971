import { createI18n } from 'vue-i18n';
import en from './en';
import cn from './cn';
import tw from './tw';

const i18n = createI18n({
  locale: 'cn',
  messages: { en, cn, tw },
});

export function tl(prefix) {
  return function (text, params) {
    return this.$t(prefix + '.' + text, params);
  };
}

export default i18n;
