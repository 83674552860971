import { setTitle } from '@admin/utils';
import { useRouter } from '@admin/hooks';
import { setEnv as _setEnv } from '@/components';

export function setEnv(env) {
  _setEnv(env);

  const { router } = useRouter();

  setTitle(router.currentRoute.value, env);
}

if (['LOCAL', 'DEV'].includes(import.meta.env.VITE_APP_ENV)) {
  window.setEnv = setEnv;
}
