<template>
  <!-- prettier-ignore -->
  <NLayoutFooter class="n-layout-footer fixed !bottom-0 !pl-2.5 !pt-0.5 text-center sm:text-left lg:text-center" :class="{ collapsed }">
    <span class="text-sm font-semibold italic">{{ COPYRIGHT }}</span>
    <span class="fixed bottom-4 right-8 text-xs hidden sm:block">
      <clock-circle-outlined class="mr-0.5" /> {{ ticker }}
    </span>
  </NLayoutFooter>
</template>

<script setup>
import { COPYRIGHT } from '@/constant';
import { onMounted, onUnmounted, ref } from 'vue';
import { useLanguage, useLayout } from '@admin/hooks';

const { collapsed } = useLayout();

const { curLang } = useLanguage();

const ticker = ref('-');

const LOCALE_MAP = { en: 'en', cn: 'zh-CN', tw: 'zh-TW' };

const INTL_OPTION = { dateStyle: 'full', timeStyle: 'long', hourCycle: 'h23' };

let tickTimer = null;

onMounted(() => {
  tickTimer = setInterval(() => {
    ticker.value = new Intl.DateTimeFormat(LOCALE_MAP[curLang.value] ?? LOCALE_MAP.en, INTL_OPTION).format(new Date());
  }, 1000);
});

onUnmounted(() => clearInterval(tickTimer));
</script>

<style lang="less">
@import '/src/page-admin/style/config';

.n-layout-footer {
  width: calc(100% - @side-menu-width);
  height: @footer-height;
  line-height: @footer-height;
  border-left: 1px solid #f0f2f5;
  z-index: 100;
  color: #ffffff;

  &.collapsed {
    width: calc(100% - @side-menu-collapsed-width);
  }
}
</style>
