<template>
  <!-- prettier-ignore -->
  <NLayoutSider v-model:collapsed="collapsed" class="n-layout-sider select-none" collapsible breakpoint="lg" @collapse="toggleCollapse" @breakpoint="toggleCollapse">
    <div class="n-logo">
      <template v-if="collapsed">
        <img src="/images/logo.png" />
      </template>
      <template v-else>
        {{ adminName }}
        <NPopover v-if="!collapsed" class="ml-2">
          <template #content>
            <NRow v-for="(item, index) in popoverContents" :key="index" class="pr-4" :class="{ 'mt-1': index > 0 }">
              <NCol :span="16" class="!text-xs !font-bold !text-gray-600">
                <environment-outlined v-if="item.label === 'env'" />
                <branches-outlined v-if="item.label === 'branch'" />
                <number-outlined v-if="item.label === 'version'" />
                {{ $tl(item.label) }}:
              </NCol>
              <NCol :span="8" class="!text-xs !text-gray-500">{{ item.value }}</NCol>
            </NRow>
          </template>
          <span class="font-mono text-xs font-light tracking-tighter">[<span class="underline cursor-pointer">{{ env }}</span>]</span>
        </NPopover>
      </template>
    </div>
    <NMenu :selected-keys="selectedKeys" :open-keys="collapsed ? [] : openKeys" :inline-indent="20" theme="dark" mode="inline" @click="onMenuClick">
      <NMenuItem :key="dashboard.name" class="dashboard" :class="{ collapsed }">
        <template #icon><home-filled /></template>
        <span v-show="!collapsed">{{ $t(dashboard.name) }} </span>
      </NMenuItem>
      <NSubMenu v-for="menu in curMenus" :key="menu.name">
        <template #title><span>{{ $t(menu.name) }}</span></template>
        <template #icon><i :class="menu.icon" /></template>
        <template v-for="submenu in menu.children">
          <NMenuItem v-if="!Array.isArray(submenu.meta.cacheName)" :key="submenu.name">{{ $t(submenu.name) }}</NMenuItem>
        </template>
      </NSubMenu>
    </NMenu>
  </NLayoutSider>
</template>

<script setup>
import menus from '@admin/menus';
import { env } from '@/components';
import { computed, ref } from 'vue';
import dashboard from '@admin/menus/dashboard';
import { onBeforeRouteUpdate } from 'vue-router';
import { useLayout, useMenus, useRouter, useTabs } from '@admin/hooks';
import { adminName, DEFAULT_PORTAL, X_BRANCH_NAME, X_VERSION } from '@/glue';

const popoverContents = computed(() => [
  { label: 'env', value: env.value },
  { label: 'branch', value: X_BRANCH_NAME },
  { label: 'version', value: X_VERSION },
]);

const collapsed = ref(false);

const { curMenus, flatMenus } = useMenus(menus);

const { tabsVisible, setTab } = useTabs();

const { setCollapsed } = useLayout();

const { router, routerTo } = useRouter();

const activeRouter = computed(() => flatMenus.value?.find(o => o.name === router.currentRoute.value.name) ?? dashboard);

const selectedKeys = computed(() => {
  return activeRouter.value.isSub ? [activeRouter.value.parent.name] : [activeRouter.value.name];
});

const openKeys = ref(curMenus.value?.map(o => o.name));

onBeforeRouteUpdate((to, from, next) => {
  if (to.name === dashboard.name) {
    next();
    return;
  }
  const active = !!flatMenus.value?.find(o => o.name === to.name);
  if (active) next();
  else next(DEFAULT_PORTAL);
});

const toggleCollapse = collapsed => setCollapsed(collapsed);

const onMenuClick = ({ key }) => {
  if (tabsVisible.value) setTab(key);
  else routerTo(key === dashboard.name ? dashboard.path : flatMenus.value.find(o => o.name === key).path);
};
</script>

<style lang="less">
.n-layout-sider {
  z-index: 100;

  .n-logo {
    height: 32px;
    margin: 16px 12px;
    background: rgba(255, 255, 255, 0.3);
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-layout-sider-collapsed .n-logo {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-menu.ant-menu-root {
    height: calc(100vh - 64px - 48px) !important;
    overflow-y: auto !important;

    .ant-menu-item.dashboard {
      width: 100%;
      margin: 0;
      left: 0;
      padding: 0 calc(50% - 16px / 2);
      text-overflow: clip;

      &.collapsed {
        height: 40px !important;
        text-align: center;
      }

      &:not(.collapsed) {
        height: 40px !important;
      }
    }

    .ant-menu-sub .ant-menu-item {
      padding-left: 46px !important;
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-submenu {
        height: 40px;

        .ant-menu-submenu-title {
          width: 100%;
          padding: 0;
          margin: 0;
          text-align: center;

          i.ant-menu-item-icon {
            width: 20px;
            height: 40px;
            margin: 0 auto;
            display: block;
          }
        }
      }
    }

    &:not(.ant-menu-inline-collapsed) {
      .ant-menu-submenu-title {
        pointer-events: none;
      }
    }
  }

  .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu-item {
    height: 30px !important;
    line-height: 30px !important;
  }
}

.ant-menu-inline-collapsed-tooltip.ant-tooltip {
  display: none;
}

.ant-menu-submenu-popup .ant-menu.ant-menu-sub li.ant-menu-item {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
}
</style>
