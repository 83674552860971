import * as Sentry from '@sentry/browser';
import { MySentry } from '@/services';
import { X_VERSION, X_BRANCH_NAME } from '@/glue';

export let mySentry;

export function initSentry(dsn, environment) {
  mySentry = new MySentry({
    dsn,
    environment,
    sentry: Sentry,
    debug: import.meta.env.VITE_APP_ENV === 'LOCAL',
    integrations: function (integrations) {
      // integrations will be all default integrations
      return integrations.filter(function (integration) {
        return integration.name !== 'Dedupe';
      });
    },
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'EvalError: Possible side-effect in debug-evaluate'],
  });

  mySentry.setTag('X_VERSION', X_VERSION);
  mySentry.setTag('X_BRANCH_NAME', X_BRANCH_NAME);
  return mySentry;
}
