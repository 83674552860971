import { createApp } from 'vue';
import '@/style/tailwind.css';
import '@/style/index.less';
import Components from '@/components';
import Directives from '@/directives';
import { setConfig } from '@/glue';
import { errorHandler } from '@/services';
import BigNumber from 'bignumber.js';

BigNumber.config({
  EXPONENTIAL_AT: 1e9, // Almost never return exponential notation
});

setConfig({
  X_VERSION: import.meta.env.X_VERSION,
  X_BRANCH_NAME: import.meta.env.X_BRANCH_NAME,
});

console.log('BUILD_TIME', import.meta.env.BUILD_TIME);

if (import.meta.env.VITE_APP_SENTRY === 'true') {
  import('@/monitor').then(({ initSentry }) => initSentry());
}

export function initApp(App, callback) {
  const app = createApp(App);
  app.use(Components);
  app.use(Directives);

  app.config.errorHandler = errorHandler;
  app.config.productionTip = false;

  if (callback) {
    callback(app)
      .then(() => {
        app.mount('#app');
      })
      .catch(errorHandler);
  } else app.mount('#app');
}
