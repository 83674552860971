<template>
  <div class="n-view-container">
    <div class="w-full"><slot name="opts" /></div>
    <div ref="contentRef" class="m-0 p-0 w-full overflow-auto">
      <slot />
      <NBackTop v-if="backTopVisible" :target="() => contentRef" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useBackTop } from '@admin/hooks';

const contentRef = ref();

const { backTopVisible } = useBackTop();
</script>

<style lang="less">
@import '/src/page-admin/style/config';

.n-view-container {
  z-index: 100;
  padding: 24px;
  width: 100%;
  height: calc(100vh - @head-height - @footer-height - @content-margin * 2);
  display: grid;
  grid-template-rows: auto 1fr;
  background: #ffffff;

  .ant-table-header {
    background-color: whitesmoke;
  }
}
</style>
