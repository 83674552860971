<template>
  <div class="identification-descriptions">
    <!-- 个人信息 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90">
      <idcard-outlined /> {{ $tl('identificationStep1Title') }}
    </div>
    <NDescriptions class="n-description customer-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('givenNameEn')">
        <NSpan :value="identification.givenNameEn" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('middleNameEn')">
        <NSpan :value="identification.middleNameEn" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('familyNameEn')">
        <NSpan :value="identification.familyNameEn" />
      </NDescriptionsItem>
    </NDescriptions>
    <NDescriptions class="n-description customer-info mt-2" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('countryOrRegion')">
        <NEnumName :value="identification.country" name="Country" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep2OnlyTaxInCountry')">
        <NIconBoolean :value="identification.onlyTaxInCountry" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep2SameBirthNationality')">
        <NIconBoolean :value="identification.sameBirthNationality" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep2NonUSResident')">
        <NIconBoolean :value="identification.nonUSResident" />
      </NDescriptionsItem>
    </NDescriptions>
    <NDescriptions class="n-description customer-info mt-2" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('idType')">
        <NEnumName :value="identification.idType" name="IdentificationType" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('idNumber')">
        <NSpan :value="identification.idNumber" />
      </NDescriptionsItem>
    </NDescriptions>

    <!-- 投资知识 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-12">
      <transaction-outlined /> {{ $tl('identificationStep4Title') }}
    </div>
    <NDescriptions class="n-description invest-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('identificationStep4KlMaxInvestment')">
        <NEnumName :value="identification.klMaxInvestment" name="QMaxInvestmentOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep4KlTrigger')">
        <NEnumName :value="identification.klTrigger" name="QTriggerOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep4KlAutoClose')">
        <NEnumName :value="identification.klAutoClose" name="AutoCloseOption" />
      </NDescriptionsItem>
    </NDescriptions>

    <!-- 适当性评估 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-12">
      <schedule-outlined /> {{ $tl('identificationStep5Title1') }}
    </div>
    <div class="text-sm font-bold mb-0.5">【{{ $tl('identificationStep5Title2') }}】</div>
    <NDescriptions class="n-description appropriateness-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('identificationStep5AxTradingCount')">
        <NEnumName :value="identification.axTradingCount" name="QTradingCountOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep5AxOtcCount')">
        <NEnumName :value="identification.axOtcCount" name="QTradingCountOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep5AxOtcLeverage')">
        <NEnumName :value="identification.axOtcLeverage" name="QOtcLeverageOption" />
      </NDescriptionsItem>
    </NDescriptions>

    <!-- 专业经验 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-12">
      <field-time-outlined /> {{ $tl('identificationStep6Title') }}
    </div>
    <NDescriptions class="n-description appropriateness-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('identificationStep6ExpHasFinanceEducation')">
        <NIconBoolean :value="identification.expHasFinanceEducation" />
      </NDescriptionsItem>
      <NDescriptionsItem v-if="identification.expHasFinanceEducation" :label="$tl('identificationStep6ExpFinanceEducation')">
        <NEnumName :value="identification.expFinanceEducation" name="QFinanceEducationOption" />
      </NDescriptionsItem>
    </NDescriptions>

    <!-- 就业信息 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-12">
      <code-outlined /> {{ $tl('identificationStep7Title') }}
    </div>
    <NDescriptions class="n-description appropriateness-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('identificationStep7Occupation')">
        <NEnumName :value="identification.occupation" name="QOccupationOption" />
      </NDescriptionsItem>
      <NDescriptionsItem v-if="identification.occupation === EMPLOYEE" :label="$tl('identificationStep7Industry')">
        <NEnumName :value="identification.industry" name="QIndustryOption" />
      </NDescriptionsItem>
    </NDescriptions>

    <!-- 财务资料 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-12">
      <account-book-outlined /> {{ $tl('identificationStep8Title') }}
    </div>
    <NDescriptions class="n-description appropriateness-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('identificationStep8finAnnualIncome')">
        <NEnumName :value="identification.finAnnualIncome" name="QFinancialAmountOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep8finSavingsInvestment')">
        <NEnumName :value="identification.finSavingsInvestment" name="QFinancialAmountOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep8finIncomeSource')">
        <NEnumName :value="identification.finIncomeSource" name="QIncomeSourceOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep8maxInvestmentLoss')">
        <NEnumName :value="identification.maxInvestmentLoss" name="QFinancialAmountOption" />
      </NDescriptionsItem>
    </NDescriptions>

    <!-- 交易概况 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-12">
      <line-chart-outlined /> {{ $tl('identificationStep9Title') }}
    </div>
    <NDescriptions class="n-description appropriateness-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('identificationStep9TradingFrequency')">
        <NEnumName :value="identification.tradingFrequency" name="QTradingFrequencyOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep9DepositMethod')">
        <NEnumName :value="identification.depositMethod" name="QDepositMethodOption" />
      </NDescriptionsItem>
      <NDescriptionsItem :label="$tl('identificationStep9AcknowledgeTradingRisk')">
        <NIconBoolean :value="identification.acknowledgeTradingRisk" />
      </NDescriptionsItem>
    </NDescriptions>

    <!-- 条款和条件 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-12">
      <file-pdf-outlined /> {{ $tl('identificationStep10Title') }}
    </div>
    <ul class="mb-1.5" style="list-style: disc inside">
      <li v-for="(clause, index) in pdfOptions" :key="index" class="p-1 text-sm font-bold">
        <a :href="clause.value" target="_blank">{{ $tl(clause.label) }}</a>
      </li>
    </ul>
    <NDescriptions class="n-description appropriateness-info" bordered :column="DESC_1_COLUMN">
      <NDescriptionsItem :label="$tl('identificationStep10ConsentAgreement')">
        <NIconBoolean :value="identification.consentAgreement" />
      </NDescriptionsItem>
    </NDescriptions>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { DESC_1_COLUMN } from '@admin/utils';
import { useBootstrap, useLanguage } from '@admin/hooks';
import { IDENTIFICATION_PDF_CONTENTS } from '@/constant';

const props = defineProps({
  identification: {
    type: Object,
    default: () => {},
  },
});

const { curLang } = useLanguage();
const { ENUM } = useBootstrap();
const { REJECT, APPROVED } = ENUM.value.ApprovalStatus;
const { EMPLOYEE } = ENUM.value.QOccupationOption;

const pdfOptions = computed(() => IDENTIFICATION_PDF_CONTENTS.find(o => o.lang === curLang.value)?.contents || []);
</script>

<style lang="less">
.identification-descriptions {
  .n-description {
    .ant-descriptions-item-label {
      width: 30% !important;
    }

    .ant-descriptions-item-content {
      width: 70% !important;
    }
  }
}
</style>
