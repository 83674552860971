<template>
  <NChannelSwitch>
    <div :class="['inline-block', 'cursor-pointer', { [`!text-${theme}`]: theme }]">
      {{ curLabel }} <down-outlined class="!text-[10px] !align-baseline" />
    </div>
  </NChannelSwitch>
</template>

<script setup>
import { computed } from 'vue';
import { useLanguage } from '@/hooks';
import { channels, curChannel, AUTO_CHANNEL } from '@/services';

const props = defineProps({
  theme: String,
});

const { tl } = useLanguage();

const curLabel = computed(() =>
  curChannel.value === AUTO_CHANNEL ? tl('autoChannel') : `${tl('channel')} ${channels.value.indexOf(curChannel.value) + 1}`,
);
</script>

<script>
export default {
  name: 'NAdminChannelSwitch',
};
</script>
