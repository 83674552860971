<template>
  <NLayout v-if="bootstrapSucceed" class="n-layout" :class="{ tabs: tabsVisible }">
    <LayoutSidebar />
    <NLayout>
      <LayoutHeader />
      <LayoutTabs v-if="tabsVisible" />
      <NLayoutContent>
        <RouterView v-if="tabsVisible" v-slot="{ Component, route }">
          <KeepAlive :include="keepAlives">
            <component :is="Component" :key="route.path" />
          </KeepAlive>
        </RouterView>
        <RouterView v-else />
      </NLayoutContent>
      <LayoutFooter />
    </NLayout>
    <NPanelBox />
  </NLayout>
</template>

<script setup>
import menus from "@admin/menus";
import { mySentry } from "@/monitor";
import { onUnmounted, ref } from "vue";
import dashboard from "@admin/menus/dashboard";
import { bootstrap } from "@admin/api/utility";
import { NavigatorMessage, NoAuthMessage } from "@/models";
import { useBootstrap, useMenus, useRouter, useTabs } from "@admin/hooks";

const { router, routerTo } = useRouter();

const bootstrapSucceed = ref(false);

try {
  await bootstrap();
  bootstrapSucceed.value = true;
} catch (err) {
  if (err instanceof NoAuthMessage) {
    const redirectPath = router.currentRoute.value.fullPath;
    const path = redirectPath ? `/login?redirect=${redirectPath}` : "/login";
    throw new NavigatorMessage({ path, type: "replace" });
  } else {
    throw err;
  }
}

const { isItUser, isAgentUser } = useBootstrap();
const { flatMenus } = useMenus(menus);
const { tabsVisible, curTab, keepAlives, setTab } = useTabs();

const currentRoute = router.currentRoute.value;

// 当前菜单访问权限
const menuPrivilege = !(
  isItUser.value && (currentRoute.meta?.hidden?.includes("ROLE_IT"))
  || isAgentUser.value && currentRoute.meta?.hidden?.includes("ROLE_AGENT")
);

if (menuPrivilege) {
  if (tabsVisible.value) {
    const isSub = Array.isArray(currentRoute.meta.cacheName);
    if (isSub) {
      const parent = flatMenus.value.find(o => o.meta.cacheName === currentRoute.meta.cacheName[0]);
      setTab(parent.name, currentRoute.fullPath);
    } else {
      if (currentRoute.name === dashboard.name && !currentRoute.params.isLogin) setTab(dashboard.name);
      if (currentRoute.name !== dashboard.name && currentRoute.name !== curTab.value.name) setTab(currentRoute.name, currentRoute.fullPath);
      else setTab(curTab.value.name);
    }
  }
}

// 没有访问权限时
// * tabs模式：跳转到上次最后访问的页面
// * 非tabs模式：跳转到dashboard页面
else tabsVisible.value ? setTab(curTab.value.name) : routerTo(dashboard.path);

onUnmounted(() => mySentry?.setUser({}));
</script>

<style lang="less">
@import '/src/page-admin/style/config';

.n-layout {
  min-height: 100vh;

  .ant-layout-content {
    margin: @content-margin @content-margin 0 @content-margin;
  }
}
</style>
