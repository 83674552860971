<template>
  <span class="n-number" :title="title">
    {{ label }}
    <span v-if="unit" class="n-number-unit">{{ unit }}</span>
  </span>
</template>

<script setup>
import { computed } from 'vue';
import BigNumber from 'bignumber.js';
import { useBootstrap } from '@admin/hooks';

const props = defineProps({
  value: undefined,
  scale: Number,
  suffix: String,
});

const { ENUMS } = useBootstrap();

const label = computed(() => new BigNumber(props.value).toFormat(props.scale));

const unit = computed(() => {
  if (!props.suffix) return '';
  const { Currency } = ENUMS.value;
  const currency = Currency?.find(o => o.name === props.suffix);
  return currency ? currency.label : props.suffix;
});

const title = computed(() => `${label.value} ${unit.value}`);
</script>

<style lang="less">
.n-number-unit {
  font-size: 10px;
  font-weight: bold;
  opacity: 0.6;
}
</style>
