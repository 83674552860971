export const VERIFY = {
  IDENTIFICATION: 'identification',
  WITHDRAWAL: 'withdrawal',
};

export const TRANSFER = {
  IT_INITIATE: 'itInitiate',
  IT_PROCESSING: 'itProcessing',
  IT_Edit: 'itEdit',
  IT_CANCEL: 'itCancel',
  IT_CONFIRM: 'itConfirm',
  IT_FORCE_CANCEL: 'itForceCancel',
  IT_FORCE_CONFIRM: 'itForceConfirm',
  CUSTOMER_FORCE_CANCEL: 'customerForceCancel',
  CUSTOMER_FORCE_CONFIRM: 'customerForceConfirm',
};
