<template>
  <NButton type="primary" class="n-back-parent" ghost @click="routerTo(curMenu.parent.path)">
    <template #icon><left-outlined /></template>
    {{ $tl('back') }}
  </NButton>
</template>

<script setup>
import { computed } from 'vue';
import menus from '@admin/menus';
import { useMenus, useRouter } from '@admin/hooks';

const { flatMenus } = useMenus(menus);

const { router, routerTo } = useRouter();

const curMenu = computed(() => flatMenus.value.find(o => o.name === router.currentRoute.value.name));
</script>

<style lang="less">
.n-back-parent:hover {
  background: var(--ant-primary-color) !important;
  color: @white !important;
}
</style>
