import * as Common from './common';
import * as Template from './template';
import * as PanelInstance from './panel-instance';
export * from './utils';

const components = {
  ...Common,
  ...Template,
  ...PanelInstance,
};

function install(app) {
  Object.entries(components).forEach(([name, comp]) => {
    app.component(name, comp);
  });
}

export default { install };
