import menus from '../menus';
import { setTitle } from '@admin/utils';
import { DEFAULT_PORTAL } from '@/glue';
import { showConfirm } from '@/components';
import { useLanguage } from '@admin/hooks';
import Login from '@admin/views/Login.vue';
import Error from '@admin/views/Error.vue';
import Index from '../views/index/index.vue';
import dashboard from '@admin/menus/dashboard';
import { createRouter, createWebHashHistory } from 'vue-router';

const indexRoutes = [];

menus.forEach(menu => {
  menu.children?.forEach(submenu => {
    indexRoutes.push({
      name: submenu.name,
      path: menu.path + submenu.path,
      meta: submenu.meta,
      component: submenu.component,
    });
  });
});

export const routes = [
  {
    path: '/',
    name: 'index',
    redirect: DEFAULT_PORTAL,
    component: Index,
    children: [dashboard, ...indexRoutes],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: DEFAULT_PORTAL,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.onError((err = {}) => {
  if (err.name === 'TypeError' && err.message.startsWith('Failed to fetch dynamically imported module')) {
    const { t, PREFIX } = useLanguage();
    showConfirm({
      title: t('resourceNotFoundTitle', PREFIX.CONFIRM),
      content: t('resourceNotFoundContent', PREFIX.CONFIRM),
      onOk() {
        window.location.reload();
      },
    });
  }
});

router.beforeResolve((to, from, next) => {
  setTitle(to);
  next();
});

export default router;
