<template>
  <span :class="className" :title="label">{{ label }}</span>
</template>

<script setup>
import { computed } from 'vue';
import { useBootstrap } from '@admin/hooks';

const props = defineProps({
  name: [String, Array],
  value: undefined,
  align: { type: String, default: 'left' },
  replacement: { type: String, default: '-' },
  showValue: Boolean,
});

const className = computed(() => `n-selection-name n-span-ellipsis n-text-${props.align}`);

const label = computed(() => {
  const selection = useBootstrap()?.[props.name];
  const cur = selection?.[props.value];
  return (props.showValue ? (cur ? `${cur.name} (${props.value})` : undefined) : cur?.name) ?? '-';
});
</script>
