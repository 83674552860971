<template>
  <span class="n-enum-name" :title="label" :style="{ color: enumColor }">{{ label }}</span>
</template>

<script setup>
import { computed } from 'vue';
import { useLanguage } from '@/hooks';
import { ENUM_COLOR } from '@/constant';
import { useBootstrap } from '@admin/hooks';

const props = defineProps({
  name: [String, Array],
  value: undefined,
  placeholder: { type: String, default: '-' },
});

const { te, t, PREFIX } = useLanguage();
const { ENUMS } = useBootstrap();

const label = computed(() => {
  if (!props.value) return props.placeholder;
  const labelKey = `${props.name}.${props.value}`;
  return te(labelKey, PREFIX.ENUMS)
    ? t(labelKey, PREFIX.ENUMS)
    : ENUMS.value[props.name]?.find(o => o.value === props.value)?.label ?? props.value;
});

const enumColor = computed(() => ENUM_COLOR[props.name]?.[props.value]);
</script>
