<template>
  <span :title="label">{{ label }}</span>
</template>

<script setup>
import { computed } from 'vue';
import { useBootstrap } from '@admin/hooks';

const { ENUMS } = useBootstrap();

const props = defineProps({
  name: [String, Array],
  value: undefined,
  placeholder: { type: String, default: '-' },
});

const label = computed(() => {
  const value = props.value;
  const placeholder = props.placeholder;
  if (!value) return placeholder;
  const currencies = value.split('/');
  if (currencies.length !== 2) return placeholder;
  const { Currency } = ENUMS.value;
  const depositCurrency = Currency.find(o => o.value === currencies[0])?.label;
  const accountCurrency = Currency.find(o => o.value === currencies[1])?.label;
  return `${depositCurrency}/${accountCurrency}`;
});
</script>
