<template>
  <!-- prettier-ignore -->
  <div class="w-full h-full">
    <NAlert type="info" show-icon class="!mb-4">
      <template #message>
        <div class="text-[14px] text-gray-500 font-bold">{{ $t('panel.alert.withdrawal') }}</div>
      </template>
    </NAlert>
    <NTable :data-source="records" :columns="columns" :loading="loading" :pagination="pagination" @pagination="onPaginationChange" @sort="onSort">
      <template #bodyName="{ record }">
        <NSpan :value="`${record.customerPo.fullName ?? '-'} (${record.customerPo.username})`" />
      </template>
      <template #bodyAction="{ record }">
        <NButton type="text" shape="circle" danger :title="$tl('verify2')" @click="openVerifyModal(record.customerWithdrawalFlowPo.id)">
          <template #icon><SafetyOutlined /></template>
        </NButton>
      </template>
    </NTable>
  </div>
</template>

<script>
import { Column, SORT_TYPE } from '@admin/utils';
import { ENUM_TAG, QUERY } from '@admin/constant';
import { computed, defineComponent, reactive } from 'vue';
import { useBootstrap, usePaginationTable } from '@admin/hooks';
import { fetch } from '@admin/api/cash/customer-withdrawal-flow';
import Modal from '@admin/views/index/cash/customer_withdrawal_flow/ModalCustomerWithdrawalVerify.vue';

export default defineComponent({
  setup() {
    const { ENUM, ENUMS, isSuperUser } = useBootstrap();

    const { SUBMIT } = ENUM.value.ApprovalStatus;
    const { ApprovalStatus: TAG_STYLE } = ENUM_TAG;

    const query = reactive({ ...QUERY.CASH.CUSTOMER_WITHDRAWAL_FLOW({ approvalStatus: SUBMIT }) });

    const { records, loading, pagination, sorter, onPaginationChange, onSort, onSearch, createModal } = usePaginationTable({
      fetch,
      query,
      sorter: { field: ['customerWithdrawalFlowPo', 'id'], order: 'descend' },
    });

    const col = new Column({ sort: SORT_TYPE.SERVER, sorter });
    const columns = computed(() => {
      const WITHDRAWAL = 'customerWithdrawalFlowPo';
      const CUSTOMER = 'customerPo';
      return isSuperUser.value
        ? [
            col.id({ key: `${WITHDRAWAL}.id` }),
            col.bodySlot({ slotKey: 'name', key: `${CUSTOMER}.fullName`, align: 'left', width: '12%' }),
            col.selection({ key: `${WITHDRAWAL}.itId`, name: 'It' }),
            col.selection({ key: `${WITHDRAWAL}.agentId`, name: 'Agent' }),
            col.id({ key: `${WITHDRAWAL}.customerId`, width: '8%' }),
            col.enum({ key: `${WITHDRAWAL}.bank`, label: 'bankName', name: 'Bank' }),
            col.shortNumber({ key: `${WITHDRAWAL}.number`, label: 'receiverNumber' }),
            col.number({
              key: `${WITHDRAWAL}.withdrawalAmount`,
              width: '11%',
              suffix: `${WITHDRAWAL}.withdrawalCurrency`,
              showZero: true,
            }),
            col.datetime({ key: `${WITHDRAWAL}.createdDate` }),
            col.datetime({ key: `${WITHDRAWAL}.lastModifiedDate` }),
            col.actionSlot(),
          ]
        : [
            col.id({ key: `${WITHDRAWAL}.id` }),
            col.bodySlot({ slotKey: 'name', key: `${CUSTOMER}.fullName`, align: 'left', width: '12%' }),
            col.selection({ key: `${WITHDRAWAL}.agentId`, name: 'Agent' }),
            col.id({ key: `${WITHDRAWAL}.customerId`, width: '8%' }),
            col.enum({ key: `${WITHDRAWAL}.bank`, label: 'bankName', name: 'Bank' }),
            col.shortNumber({ key: `${WITHDRAWAL}.number`, label: 'receiverNumber' }),
            col.number({
              key: `${WITHDRAWAL}.withdrawalAmount`,
              width: '11%',
              suffix: `${WITHDRAWAL}.withdrawalCurrency`,
              showZero: true,
            }),
            col.datetime({ key: `${WITHDRAWAL}.createdDate` }),
            col.datetime({ key: `${WITHDRAWAL}.lastModifiedDate` }),
            col.actionSlot(),
          ];
    });

    const openVerifyModal = id => createModal(Modal, { id, action: 'edit' });

    return {
      records,
      loading,
      pagination,
      onPaginationChange,
      onSort,
      onSearch,
      ENUMS,
      SUBMIT,
      TAG_STYLE,
      columns,
      openVerifyModal,
    };
  },
});
</script>
