<template>
  <!-- prettier-ignore -->
  <NPanel :id="id" class="n-verify-panel">
    <template #header>
      <safety-outlined class="ml-2 mr-[4px] !align-middle" />
      <span class="inline-block font-bold !align-middle">{{ $t('panel.verify') }}</span>
    </template>
    <NTabs v-model:activeKey="activeKey" type="card" size="small">
      <NTabPane :key="VERIFY.IDENTIFICATION" :tab="$t(`panel.${VERIFY.IDENTIFICATION}`)" />
      <NTabPane :key="VERIFY.WITHDRAWAL" :tab="$t(`panel.${VERIFY.WITHDRAWAL}`)" />
    </NTabs>
    <div class="w-full h-full !overflow-auto" :class="activeKey">
      <Identification v-if="activeKey === VERIFY.IDENTIFICATION" ref="identificationRef" />
      <Withdrawal v-if="activeKey === VERIFY.WITHDRAWAL" ref="withdrawalRef" />
    </div>
    <template #footer>
      <NButton type="text" size="small" class="extra-btn mt-[1px]" @click.prevent.stop="onRefresh">
        <template #icon><reload-outlined /></template><span class="!text-xs">{{ $tl('manualRefresh') }}</span>
      </NButton>
      <NDivider type="vertical" class="bg-gray-200 !mx-2" />
      <NRefreshTime @refresh="onRefresh" />
    </template>
  </NPanel>
</template>

<script>
import { usePanel } from '@admin/hooks';
import { VERIFY } from '@admin/constant';
import Withdrawal from './Withdrawal.vue';
import Identification from './Identification.vue';
import { defineComponent, onUnmounted, ref } from 'vue';

export default defineComponent({
  name: 'VerifyPanel',

  components: { Identification, Withdrawal },

  props: { id: String },

  setup(props) {
    const activeKey = ref(VERIFY.IDENTIFICATION);

    const { closePanel } = usePanel();

    const identificationRef = ref();

    const withdrawalRef = ref();

    onUnmounted(() => closePanel(props.id));

    const onSearch = key => {
      switch (key) {
        case VERIFY.IDENTIFICATION:
          return identificationRef.value.onSearch();
        case VERIFY.WITHDRAWAL:
          return withdrawalRef.value.onSearch();
      }
    };

    const onRefresh = () => onSearch(activeKey.value);

    return { VERIFY, activeKey, identificationRef, withdrawalRef, onSearch, onRefresh };
  },
});
</script>

<style lang="less">
.n-verify-panel {
  .n-panel-body {
    width: 100%;
    height: 100%;
    padding: 16px 24px 52px 24px;
    overflow: hidden !important;

    .ant-alert-info {
      background-color: #fafafa;
      border: 1px solid #e8eaec;
    }

    .identification .n-table {
      min-width: 1250px;
    }

    .withdrawal .n-table {
      min-width: 1380px;
    }
  }
}
</style>
