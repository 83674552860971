<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <div class="n-input-objects-control">
      <div v-for="(item, index) in mValue" :key="index" class="flex flex-row space-x-1 !mb-1">
        <div class="w-full flex flex-row space-x-1">
          <ASelect v-model:value="item.type" v-bind="selectAttrs" @change="emitValue()" />
          <AInput v-model:value="item.text" v-bind="inputAttrs" @change="emitValue()" />
        </div>
        <div class="!w-10">
          <NButton v-bind="removeBtnAttrs" @click="onRemoveItem(index)">
            <template #icon><delete-outlined /></template>
          </NButton>
        </div>
      </div>
      <NButton v-bind="addBtnAttrs" @click="onAddItem">
        <template #icon><PlusOutlined /></template>{{ $tl('add') }}
      </NButton>
    </div>
  </NFormItem>
</template>

<script setup>
import { ref, useAttrs, computed } from 'vue';
import { Select as ASelect, Input as AInput } from 'ant-design-vue/es';
import { useFormItemBaseAttrs } from '@/components/common/hooks/useFormItemBaseAttrs';

const props = defineProps({
  label: String,
  name: [String, Array],
  customLabel: String,
  value: Array, // { type: String | Number, text: String }[]
  options: Array, // { value: String | Number, label: String }[]
});

const emit = defineEmits(['update:value', 'change', 'addItem', 'removeItem']);

const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);
const attrs = useAttrs();

const mValue = ref(props.value ?? []);

const selectAttrs = computed(() => ({
  options: props.options || [],
  disabled: attrs.disabled || componentBaseAttrs.value.disabled,
  class: '!w-[45%]',
}));

const inputAttrs = computed(() => ({
  type: 'text',
  disabled: attrs.disabled || componentBaseAttrs.value.disabled,
  class: '!w-full',
}));

const removeBtnAttrs = computed(() => ({
  type: 'primary',
  disabled: attrs.disabled || mValue.value.length === 1 || componentBaseAttrs.value.disabled,
  class: '!w-full',
}));

const addBtnAttrs = computed(() => ({
  type: 'primary',
  block: true,
  disabled: props.disabled,
}));

const onRemoveItem = index => {
  mValue.value.splice(index, 1);
  emit('removeItem');
  emitValue();
};

const onAddItem = () => {
  mValue.value.push({ type: null, text: null });
  emit('addItem', mValue.value);
  emitValue();
};

const emitValue = () => {
  const v = mValue.value?.filter(o => !!props.options?.find(f => f.value === o.type));
  emit('update:value', v);
  emit('change', v);
};
</script>
