<template>
  <NSelect class="n-receive-currency" v-bind="componentAttrs" />
</template>

<script>
import { useBootstrap } from '@admin/hooks';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'NReceiveCurrency',

  inheritAttrs: false,

  props: { method: String },

  setup(props, { attrs, emit }) {
    const { ENUM, ENUMS } = useBootstrap();

    const componentAttrs = computed(() => {
      const method = props.method;
      const { ReceiveMethod, Currency } = ENUM.value;
      let value = attrs.value;
      if (method === ReceiveMethod.WECHAT) {
        value = Currency.CNY;
        emit('update:value', value);
      } else if (method === ReceiveMethod.USDT) {
        value = Currency.USDT;
        emit('update:value', value);
      } else if (value === Currency.USDT) {
        value = undefined;
        emit('update:value', value);
      }
      const label = 'receiveCurrency';
      const currencies = ENUMS.value.ReceiveMethod.find(o => o.value === method)?.['currencies'] ?? [];
      const options = ENUMS.value.Currency.filter(o => currencies.includes(o.value));
      const disabled = [ReceiveMethod.WECHAT, ReceiveMethod.USDT].includes(method) ? true : attrs.disabled;
      return { ...attrs, value, label, options, disabled };
    });

    return { componentAttrs };
  },
});
</script>
