<template>
  <NSelect class="n-agent" v-bind="componentAttrs" />
</template>

<script>
import { isEmpty } from 'lodash-es';
import { useBootstrap } from '@admin/hooks';
import { computed, watch, defineComponent } from 'vue';

export default defineComponent({
  name: 'NAgent',

  inheritAttrs: false,

  props: {
    itId: Number,
    filter: Function,
  },

  setup(props, { attrs, emit }) {
    const { userProfile, agents, isAgentUser } = useBootstrap();

    const componentAttrs = computed(() => {
      let value = attrs.value;
      if (isAgentUser.value) {
        value = userProfile.value.agentId;
        emit('update:value', value);
      }
      const label = attrs.label ?? 'agent';
      let selection = props.itId ? agents.value.filter(o => o.itId === props.itId) : agents.value;
      if (props.filter) selection = selection.filter(props.filter);
      const options = selection?.map(o => ({ label: `${o.label} (${o.value})`, value: o.value })) ?? [];
      const disabled = isAgentUser.value ? true : attrs.disabled;
      return { ...attrs, value, label, options, disabled };
    });

    watch(
      () => props.itId,
      to => {
        const val = componentAttrs.value.value;
        const opts = componentAttrs.value.options;
        if ([undefined, null].includes(to) || isEmpty(opts) || !opts.find(o => o.value === val)) emit('update:value', null);
      },
      { immediate: true },
    );

    return { componentAttrs };
  },
});
</script>
