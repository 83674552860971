import { env } from '@/components';
import { adminName } from '@/glue';
import { useLanguage } from '@/hooks';

export const setTitle = (route, _env) => {
  const { fullPath, name } = route;
  if (fullPath === '/' || !name) return;
  const { t } = useLanguage();
  const envText = _env ?? env.value;
  const projectName = envText === 'PROD' ? adminName : `${adminName} [${envText}]`;
  const routeName = t(name);
  document.title = fullPath === '/login' ? projectName : `${projectName} - ${routeName}`;
};
