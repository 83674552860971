<template>
  <NSelect class="n-enum" v-bind="attrs" />
</template>

<script setup>
import { computed } from 'vue';
import { useBootstrap } from '@admin/hooks';

const props = defineProps({
  label: String,
  enumName: String,
  optsDisabled: { type: Array, default: () => [] },
  filter: { type: Function },
});

const { ENUMS } = useBootstrap();

const mOptions = computed(() => {
  const ret =
    ENUMS?.value?.[props.enumName]?.map(o => ({
      label: o.label,
      value: o.value,
      disabled: !!props.optsDisabled.find(f => f === o.value),
    })) ?? [];
  return props.filter ? ret.filter(props.filter) : ret;
});

const attrs = computed(() => ({
  label: props.label,
  options: mOptions.value,
}));
</script>
