<template>
  <!-- prettier-ignore -->
  <NModal class="n-settings-modal" width="580px">
    <template #title>
      {{ $tl('settings') }}
      <NTooltip :title="$t('tooltip.settings')" placement="topLeft">
        <info-circle-outlined class="ml-0.5 !text-xs !align-baseline" />
      </NTooltip>
    </template>
    <NRow>
      <NCol :span="12" class="font-bold text-gray-600"> <global-outlined class="mr-1" />{{ $tl('language') }} </NCol>
      <NCol :span="12">
        <NRadioGroup v-model:value="lang" @change="onChangeLanguage">
          <NRadio v-for="o in langOptions" :key="o.value" :value="o.value" :style="radioStyle">
            <img :src="o.flag" class="img-country" /> {{ o.label }}
          </NRadio>
        </NRadioGroup>
      </NCol>
    </NRow>
    <NDivider class="!my-[20px]" />
    <NRow>
      <NCol :span="12" class="font-bold text-gray-600"> <appstore-add-outlined class="mr-1" />{{ $tl('tabs') }} </NCol>
      <NCol :span="12">
        <NSwitch v-model:checked="tVisible" :checked-children="$tl('on')" :un-checked-children="$tl('off')" @change="onToggleTabsVisible" />
        <NForm v-bind="formAttrs" class="!mt-6">
          <NSelect v-if="tVisible" v-model:value="tabLimit" :options="tabLimitOptions" label="tabLimit" @change="onChangeTabLimit" />
        </NForm>
      </NCol>
    </NRow>
    <NDivider class="!my-[20px]" />
    <NRow>
      <NCol :span="12" class="font-bold text-gray-600"> <table-outlined class="mr-1" />{{ $tl('table') }} </NCol>
      <NCol :span="12">
        <NForm v-bind="formAttrs">
          <NBoolean v-model:value="table.bordered" label="bordered" true-label="show" false-label="hide" @change="onChangeTable" />
          <NSelect v-model:value="table.pageSize" :options="pageSizeOptions" label="pageSize" @change="onChangeTable" />
        </NForm>
      </NCol>
    </NRow>
    <NDivider class="!my-[20px]" />
    <NRow>
      <NCol :span="12" class="font-bold text-gray-600"> <vertical-align-top-outlined class="mr-1" />{{ $tl('backTop') }} </NCol>
      <NCol :span="12">
        <NSwitch v-model:checked="btVisible" :checked-children="$tl('on')" :un-checked-children="$tl('off')" @change="onToggleBackTopVisible" />
        <NForm v-bind="formAttrs" class="!mt-6">
          <NSelect v-if="btVisible" v-model:value="visibilityHeight" :options="backTopVisibilityHeightOptions" label="visibilityHeight" @change="onChangeBackTopVisibilityHeight" />
        </NForm>
      </NCol>
    </NRow>
    <template #footer>
      <NButton type="primary" @click="openRestoreDefaultSettingsConfirmModal()">{{ $tl('restoreDefaultSettings') }}</NButton>
      <NButton @click="destroy()">{{ $tl('close') }}</NButton>
    </template>
  </NModal>
</template>

<script setup>
import { lowerCase } from 'lodash-es';
import { setTitle } from '@admin/utils';
import { ErrorMessage } from '@/models';
import { showConfirm } from '@/components';
import { computed, createVNode, ref } from 'vue';
import { Divider as NDivider } from 'ant-design-vue/es';
import { useBackTop, useInjectModalData, useLanguage, useRouter, useTabs, useTableConfig } from '@admin/hooks';

const { router } = useRouter();

const { model, destroy } = useInjectModalData();

const { curLang, options, changeLanguage, tl, t, PREFIX, clearLanguageCache } = useLanguage();

const { tabsVisible, curTabLimit, tabLimitOptions, setTabsVisible, setTabLimit, clearTabsCache } = useTabs();

const { curBordered, curPageSize, pageSizeOptions, changeTable, clearTableCache } = useTableConfig();

const {
  backTopVisible,
  backTopVisibilityHeight,
  backTopVisibilityHeightOptions,
  setBackTopVisible,
  setBackTopVisibilityHeight,
  clearBackTopCache,
} = useBackTop();

const lang = ref(curLang.value);

const table = ref({ bordered: curBordered.value, pageSize: curPageSize.value });

const tVisible = ref(tabsVisible.value);

const tabLimit = ref(curTabLimit.value);

const btVisible = ref(backTopVisible.value);

const visibilityHeight = ref(backTopVisibilityHeight.value);

const radioStyle = { display: 'flex', height: '40px', lineHeight: '40px' };

const langOptions = computed(() => options.filter(o => ['en', 'cn', 'tw'].includes(o.value)));

const formAttrs = computed(() => ({
  class: '!grid-cols-1',
  labelAlign: 'left',
  layout: 'vertical',
  style: {
    margin: '0',
    padding: '0',
    maxWidth: '180px',
  },
}));

const onChangeLanguage = async () => {
  try {
    await changeLanguage(lang.value);
  } catch (err) {
    throw new ErrorMessage({
      content: t('failed', PREFIX.ERROR, { key: tl('setting') }),
    });
  }
  try {
    await setTitle(router.currentRoute.value);
  } catch (err) {
    console.warn('set title failed', err);
  }
};

const onToggleTabsVisible = async () => {
  try {
    await setTabsVisible(tVisible.value);
  } catch (err) {
    throw new ErrorMessage({
      content: t('failed', PREFIX.ERROR, { key: tl('setting') }),
    });
  }
};

const onChangeTabLimit = async () => {
  try {
    await setTabLimit(tabLimit.value);
  } catch (err) {
    throw new ErrorMessage({
      content: t('failed', PREFIX.ERROR, { key: tl('setting') }),
    });
  }
};

const onChangeTable = async () => {
  try {
    await changeTable(table.value);
  } catch (err) {
    throw new ErrorMessage({
      content: t('failed', PREFIX.ERROR, { key: tl('setting') }),
    });
  }
};

const onToggleBackTopVisible = async () => {
  try {
    await setBackTopVisible(btVisible.value);
  } catch (err) {
    throw new ErrorMessage({
      content: t('failed', PREFIX.ERROR, { key: tl('setting') }),
    });
  }
};

const onChangeBackTopVisibilityHeight = async () => {
  try {
    await setBackTopVisibilityHeight(visibilityHeight.value);
  } catch (err) {
    throw new ErrorMessage({
      content: t('failed', PREFIX.ERROR, { key: tl('setting') }),
    });
  }
};

const openRestoreDefaultSettingsConfirmModal = () => {
  let action = lowerCase(tl('restoreDefaultSettings'));
  const title = t('title', PREFIX.CONFIRM, { action });
  showConfirm({
    width: 500,
    title,
    content: createVNode('div', { style: 'font-size: 12px;' }, [
      createVNode('br'),
      createVNode('div', { style: 'font-size: 13px; font-style: italic;' }, t('clearBrowserCache', PREFIX.WARNING)),
      createVNode(NDivider, { style: 'margin-top: 12px; margin-bottom: 12px;' }),
      createVNode('div', { style: 'width: 100%;' }, [
        createVNode('div', { style: 'display:inline-block ; width: 25%; font-weight: bold;' }, `${tl('language')}: `),
        createVNode('div', { style: 'display:inline-block ; width: 75%; font-weight: normal;' }, tl('chineseSimplified')),
      ]),
      createVNode('div', { style: 'width: 100%;' }, [
        createVNode('div', { style: 'display:inline-block ; width: 25%; font-weight: bold;' }, `${tl('tabs')}: `),
        createVNode('div', { style: 'display:inline-block ; width: 75%; font-weight: normal;' }, tl('off')),
      ]),
      createVNode('div', { style: 'width: 100%;' }, [
        createVNode('div', { style: 'display:inline-block ; width: 25%; font-weight: bold;' }, `${tl('table')}: `),
        createVNode(
          'div',
          { style: 'display:inline-block ; width: 75%; font-weight: normal;' },
          `[ ${tl('bordered')}: ${tl('hide')}, ${tl('pageSize')}: 20 ]`,
        ),
      ]),
      createVNode('div', { style: 'width: 100%;' }, [
        createVNode('div', { style: 'display:inline-block ; width: 25%; font-weight: bold;' }, `${tl('backTop')}: `),
        createVNode(
          'div',
          { style: 'display:inline-block ; width: 75%; font-weight: normal;' },
          `[ ${tl('on')}, ${tl('visibilityHeight')}: 400${tl('px')} ]`,
        ),
      ]),
      createVNode(NDivider, { style: 'margin-top: 12px; margin-bottom: 12px;' }),
      createVNode('div', { class: 'n-warning' }, t('forcedRefresh', PREFIX.WARNING)),
    ]),
    onOk() {
      return restoreDefaultSettings();
    },
  });
};

const restoreDefaultSettings = async () => {
  try {
    await clearLanguageCache();
    await clearTabsCache();
    await clearTableCache();
    await clearBackTopCache();
  } catch (err) {
    throw new ErrorMessage({
      content: t('failed', PREFIX.ERROR, { key: tl('restoreDefaultSettings') }),
    });
  } finally {
    window.location.reload();
  }
};
</script>

<style lang="less">
.n-settings-modal {
  top: 38px;

  .ant-modal-body {
    padding: 16px 0;

    & > .ant-row {
      padding: 0 40px;

      .ant-form .ant-form-item .ant-form-item-label,
      .ant-form .ant-form-item .ant-form-item-control {
        flex: none;
        max-width: 100%;
      }
    }
  }
}
</style>
