<template>
  <NConfigProvider :locale="curLocale">
    <Suspense>
      <router-view />
    </Suspense>
  </NConfigProvider>
</template>

<script setup>
import { initSentry } from '@/monitor';
import { useLanguage } from '@admin/hooks';
import { loadPublicBootstrap } from '@admin/api/utility';

const { curLocale } = useLanguage();

if (import.meta.env.VITE_APP_SENTRY === 'true') {
  loadPublicBootstrap().then(({ env, sentryInfo }) => {
    if (sentryInfo.enabled) {
      const mySentry = initSentry(sentryInfo.url, env);
      mySentry.setTag('X_ROUTE', 'CCMS_ADMIN');
    }
  });
}
</script>
