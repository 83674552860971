import { initApp } from '@/main';
import i18n, { tl } from '@admin/i18n';
import App from './App.vue';
import '@admin/style/index.less';
import router from './router';
import adminComponents, { setEnv } from './components';
import { setConfig, X_VERSION } from '@/glue';
import { useRouter, useLanguage } from '@admin/hooks';
import { setAxiosConfig, Storage, setStorage } from '@/services';
import { pingChannel, initChannels } from './api/utility';
import { checkBrowser } from '@/utils';

initApp(App, async app => {
  const { setRouter } = useRouter();
  const projectName = 'ccms_admin';
  const API_PREFIX = '/api/admin';
  setRouter(router);
  const storage = new Storage({ prefix: projectName + '_' });
  const token = storage.get('token');
  setConfig({ adminName: 'CCMS Admin', projectName, i18n, pingChannel, API_PREFIX });
  setStorage(storage);
  setAxiosConfig({ baseURL: API_PREFIX, headers: { 'x-version': X_VERSION, token } });
  app.use(i18n);
  app.use(router);
  app.use(adminComponents);
  app.config.globalProperties.$tl = tl('label');

  const { initLanguage, SYSTEM } = useLanguage();
  initLanguage(SYSTEM.ADMIN);
  await checkBrowser();
  await initChannels().catch(err => console.error('Init Channels Error:', err));
});

setEnv(import.meta.env.VITE_APP_ENV);
