<template>
  <!-- prettier-ignore -->
  <div class="w-full h-full">
    <NAlert type="info" show-icon class="!mb-4">
      <template #message>
        <div class="text-[14px] text-gray-500 font-bold">{{ $t('panel.alert.identification') }}</div>
      </template>
    </NAlert>
    <NTable :data-source="records" :columns="columns" :loading="loading" :pagination="pagination" @pagination="onPaginationChange" @sort="onSort">
      <template #bodyAction="{ record }">
        <NButton type="text" shape="circle" danger :title="$tl('verify')" @click="openVerifyModal(record.customerVerifyFlowPo.id)">
          <template #icon><SafetyOutlined /></template>
        </NButton>
      </template>
    </NTable>
  </div>
</template>

<script>
import { Column, SORT_TYPE } from '@admin/utils';
import { ENUM_TAG, QUERY } from '@admin/constant';
import { computed, defineComponent, reactive } from 'vue';
import { fetch } from '@admin/api/member/customer-verify-flow';
import { useBootstrap, useLanguage, usePaginationTable } from '@admin/hooks';
import Modal from '@admin/views/index/member/customer_verify_flow/ModalCustomerVerifyFlow.vue';

export default defineComponent({
  setup() {
    const { t, PREFIX } = useLanguage();
    const { ENUM, ENUMS, isSuperUser } = useBootstrap();

    const { SUBMIT } = ENUM.value.ApprovalStatus;
    const { ApprovalStatus: TAG_STYLE } = ENUM_TAG;

    const query = reactive({ ...QUERY.MEMBER.CUSTOMER_VERIFY_FLOW({ approvalStatus: SUBMIT }) });

    const { records, loading, pagination, sorter, onPaginationChange, onSort, onSearch, createModal } = usePaginationTable({
      fetch,
      query,
      sorter: { field: ['customerVerifyFlowPo', 'id'], order: 'descend' },
    });

    const help = computed(() => t(`verify.help.identification`, PREFIX.PANEL));

    const col = new Column({ sort: SORT_TYPE.SERVER, sorter });
    const VERIFY_FLOW = 'customerVerifyFlowPo';
    const IDENTIFICATION = 'customerIdentificationPo';
    const CUSTOMER = 'customerPo';

    const columns = computed(() => {
      return isSuperUser.value
        ? [
            col.id({ key: `${VERIFY_FLOW}.id` }),
            col.text({ key: `${CUSTOMER}.fullName` }),
            col.text({ key: `${IDENTIFICATION}.fullName`, label: 'verifyName' }),
            col.selection({ key: `${VERIFY_FLOW}.itId`, name: 'It' }),
            col.selection({ key: `${VERIFY_FLOW}.agentId`, name: 'Agent' }),
            col.shortText({ key: `${CUSTOMER}.username` }),
            col.text({ key: `${VERIFY_FLOW}.customerId`, width: '9%' }),
            col.datetime({ key: `${VERIFY_FLOW}.createdDate` }),
            col.datetime({ key: `${VERIFY_FLOW}.lastModifiedDate` }),
            col.actionSlot(),
          ]
        : [
            col.id({ key: `${VERIFY_FLOW}.id` }),
            col.text({ key: `${CUSTOMER}.fullName` }),
            col.text({ key: `${IDENTIFICATION}.fullName`, label: 'verifyName' }),
            col.selection({ key: `${VERIFY_FLOW}.agentId`, name: 'Agent' }),
            col.shortText({ key: `${CUSTOMER}.username` }),
            col.text({ key: `${VERIFY_FLOW}.customerId` }),
            col.datetime({ key: `${VERIFY_FLOW}.createdDate` }),
            col.datetime({ key: `${VERIFY_FLOW}.lastModifiedDate` }),
            col.actionSlot(),
          ];
    });

    const openVerifyModal = id => createModal(Modal, { id, action: 'edit' });

    return {
      help,
      records,
      loading,
      pagination,
      onPaginationChange,
      onSort,
      onSearch,
      ENUMS,
      SUBMIT,
      TAG_STYLE,
      columns,
      openVerifyModal,
    };
  },
});
</script>
