<template>
  <div class="n-login w-full h-screen">
    <NCard :title="`CCMS ${$tl('admin')}`">
      <NForm v-bind="formAttrs" @keydown.enter="doLogin">
        <NInput v-model:value="model.username" v-bind="usernameAttrs" />
        <NInput v-model:value="model.password" v-bind="passwordAttrs" />
        <NButton size="large" block :disabled="!isValid" @click="doLogin">
          <template #icon><i class="fas fa-sign-in-alt" /></template>
          <span>{{ $tl('login') }}</span>
        </NButton>
      </NForm>
      <template #actions>
        <div class="grid grid-rows-2 gap-2">
          <div>{{ COPYRIGHT }}</div>
          <div>
            <template v-if="isProxyMode">
              <NAdminChannelSwitch />
              <NDivider type="vertical" class="bg-gray-200 !mx-3" />
            </template>
            <NDropdown :trigger="['click']" placement="bottomRight" overlay-class-name="language-popup">
              <div class="inline-block">{{ curLanguage.label }} <down-outlined /></div>
              <template #overlay>
                <NMenu @click="onChangeLanguage">
                  <NMenuItem v-for="o in langOptions" :key="o.value" :class="{ active: o.value === curLanguage.value }">
                    <img :src="o.flag" class="img-country" /> {{ o.label }}
                  </NMenuItem>
                </NMenu>
              </template>
            </NDropdown>
          </div>
        </div>
      </template>
    </NCard>
  </div>
</template>

<script setup>
import { COPYRIGHT } from '@/constant';
import { isProxyMode } from '@/services';
import { login } from '@admin/api/utility';
import { ValidationError } from '@/models';
import { ref, reactive, computed } from 'vue';
import dashboard from '@admin/menus/dashboard';
import { useLanguage, useRouter, useValidate } from '@admin/hooks';

const { router, routerTo, linkTo } = useRouter();
const { curLanguage, options, tl, changeLanguage } = useLanguage();

const formRef = ref();
const model = reactive({ username: '', password: '' });

const langOptions = computed(() => options.filter(o => ['en', 'cn', 'tw'].includes(o.value)));

const validation = computed(() => ({
  username: { required: true },
  password: { required: true },
}));

const { isValid, rules, validateSubmit } = useValidate({ formRef, validation });

const formAttrs = computed(() => ({
  ref: formRef,
  model,
  rules: rules.value,
  class: '!grid-cols-1',
}));

const usernameAttrs = computed(() => ({
  size: 'large',
  name: 'username',
  placeholder: tl('username'),
}));

const passwordAttrs = computed(() => ({
  size: 'large',
  type: 'password',
  name: 'password',
  placeholder: tl('password'),
}));

const onChangeLanguage = $event => changeLanguage($event.key);

const doLogin = () => {
  const currentRoute = router.currentRoute.value;
  const redirect = currentRoute?.query?.redirect;
  const route = {
    name: dashboard.name,
    currentRoute: ref({
      fullPath: dashboard.path,
    }),
  };
  return validateSubmit(login, { model })
    .then(() => (redirect && redirect !== dashboard.path ? routerTo(redirect) : linkTo(route, { isLogin: true })))
    .catch(e => {
      if (!(e instanceof ValidationError)) throw e;
    });
};
</script>

<style lang="less">
.n-login .ant-card {
  width: 360px;
  margin: 0 auto;
  top: 120px;
  background-color: #f8f9fa;

  .ant-card-head {
    padding: 0 0 0 16px;
    min-height: auto;
    height: 40px;
    line-height: 40px;
    background-repeat: repeat-x;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);

    .ant-card-head-title,
    .ant-card-extra {
      padding: 0;
      color: #17233d;
    }
  }

  .ant-card-body {
    .ant-btn {
      color: #ffffff;

      & > span {
        margin-left: 8px;
      }
    }

    .ant-btn[disabled] {
      opacity: 0.6;
    }
  }

  .ant-card-actions {
    padding-top: 4px;
    background-color: #f8f9fa;
    border-top-color: #e3e8ee;
  }
}
</style>
