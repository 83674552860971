<template>
  <NModal :custom-title="$tl('changePassword')" width="520px" :ok-btn-disabled="!isValid">
    <NForm v-bind="formAttrs">
      <NInput v-model:value="model.password" name="password" label="oldPassword" type="password" />
      <NInput v-model:value="model.newPassword" name="newPassword" label="newPassword" type="password" />
      <NInput v-model:value="model.newPasswordConfirm" name="newPasswordConfirm" label="confirmPassword" type="password" />
    </NForm>
  </NModal>
</template>

<script setup>
import { computed, ref } from 'vue';
import { changePassword } from '@admin/api/utility';
import { setAxiosConfig, storage } from '@/services';
import { MIN_LENGTH, MAX_LENGTH } from '@admin/constant';
import { useInjectModalData, useLanguage, useRouter, useValidate } from '@admin/hooks';

const formRef = ref();

const { isEn } = useLanguage();

const { routerTo } = useRouter();

const { model, onOk } = useInjectModalData();

const validation = computed(() => ({
  password: { required: true, noSpaces: true, minLength: MIN_LENGTH.OPERATOR.PASSWORD, maxLength: MAX_LENGTH.OPERATOR.PASSWORD },
  newPassword: {
    required: true,
    noSpaces: true,
    minLength: MIN_LENGTH.OPERATOR.PASSWORD,
    maxLength: MAX_LENGTH.OPERATOR.PASSWORD,
  },
  newPasswordConfirm: {
    required: true,
    noSpaces: true,
    minLength: MIN_LENGTH.OPERATOR.PASSWORD,
    maxLength: MAX_LENGTH.OPERATOR.PASSWORD,
  },
}));

const { isValid, rules, validateSubmit } = useValidate({ formRef, validation });

const formAttrs = computed(() => ({
  ref: formRef,
  model,
  rules: rules.value,
  labelCol: { span: isEn.value ? 8 : 6 },
  class: '!grid-cols-1',
}));

onOk(() => {
  return validateSubmit(changePassword, { model }).then(() => {
    setAxiosConfig({ headers: { token: null } });
    storage.remove('token');
    routerTo('/login');
  });
});
</script>
