<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <BaseInput v-bind="inputAttrs" @update:value="onUpdateValue" @press-enter="$emit('pressEnter')">
      <template #addonBefore>
        <BaseSelect v-bind="selectAttrs" @change="onChangeRole" />
      </template>
    </BaseInput>
  </NFormItem>
</template>

<script>
import { useLanguage } from '@/hooks';
import { useBootstrap } from '@admin/hooks';
import { inputableIdRegex } from '@/constant';
import { computed, defineComponent } from 'vue';
import { SELECT } from '@/components/common/constant';
import { useFormItemBaseAttrs } from '@/components/common/hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NTransferor',

  inheritAttrs: false,

  props: {
    label: String,
    name: [String, Array],
    customLabel: String,
    allowClear: { type: Boolean, default: true },
  },

  setup(props, { attrs, emit }) {
    const { tl } = useLanguage();

    const { ENUM, ENUMS } = useBootstrap();

    const { formItemBaseAttrs } = useFormItemBaseAttrs(props);

    const { IT } = ENUM.value.TransferRole;

    const inputAttrs = computed(() => {
      const value = attrs.value;
      const valuePattern = inputableIdRegex;
      const disabled = attrs.disabled || attrs.role === IT;
      return { value, valuePattern, allowClear: props.allowClear, disabled };
    });

    const selectAttrs = computed(() => {
      const value = attrs.role;
      const options = [{ label: tl('all'), value: SELECT.ALL_OPTION_VALUE }, ...(ENUMS.value?.TransferRole ?? [])];
      const disabled = attrs.disabled ?? false;
      return { value, options, dropdownMatchSelectWidth: false, disabled };
    });

    const onUpdateValue = v => emit('update:value', v);

    const onChangeRole = v => {
      emit('changeRole', v);
      emit('update:role', v);
      if (v === IT) emit('update:value', null);
    };

    return { formItemBaseAttrs, inputAttrs, selectAttrs, onUpdateValue, onChangeRole };
  },
});
</script>
