<template>
  <NLayoutHeader class="n-layout-header flex">
    <div class="flex-1 flex items-center"><Breadcrumb /></div>
    <div>
      <div class="hidden lg:inline-block text-xs right-text">
        <span class="italic welcome mr-1">{{ $tl('welcome') }},</span>
        <span class="font-bold user-name">{{ userProfile.fullName }}</span>
        <template v-if="userIt">
          <NDivider type="vertical" class="bg-gray-200 !mx-3" />
          <span class="it mr-1">{{ $tl('it') }}:</span>
          <NSelectionName :value="userIt.value" name="It" show-value class="font-bold it-name" />
        </template>
        <template v-if="userAgent">
          <NDivider type="vertical" class="bg-gray-200 !mx-3" />
          <span class="agent mr-1">{{ $tl('agent') }}:</span>
          <NSelectionName :value="userAgent.value" name="Agent" show-value class="font-bold agent-name" />
        </template>
      </div>
      <template v-if="isSuperUser || isItUser">
        <NDivider type="vertical" class="!hidden lg:!inline-block bg-gray-200 !mx-3" />
        <NButton
          type="text"
          size="small"
          :title="$t('panel.verify')"
          danger
          class="extra-btn"
          :disabled="verifyBtnDisabled"
          @click="onCreateVerifyPanel"
        >
          <template #icon><safety-outlined /></template>
        </NButton>
      </template>
      <template v-if="isProxyMode">
        <NDivider type="vertical" class="!inline-block bg-gray-200 !mx-3" />
        <span class="text-white font-bold mr-2">{{ $tl('channel') }}:</span><NAdminChannelSwitch theme="white" />
      </template>
      <NDivider type="vertical" class="!hidden lg:!inline-block bg-gray-200 !ml-3 !mr-0" />
      <NDropdown :trigger="['click']" placement="bottomRight" overlay-class-name="setting-popup">
        <NButton v-bind="settingBtnAttrs" class="mt-2 !ml-4 setting-btn" @click.prevent>
          {{ userProfile.fullName?.charAt(0) }}
        </NButton>
        <template #overlay>
          <NMenu @click="onSetting">
            <NMenuItem key="password"><i class="fas fa-key" />{{ $tl('password') }}</NMenuItem>
            <NMenuItem key="settings"><i class="fas fa-cog" />{{ $tl('settings') }}</NMenuItem>
            <NMenuItem v-if="userIt || userAgent" key="help"><i class="fas fa-question-circle" />{{ $tl('help') }}</NMenuItem>
            <NMenuDivider />
            <NMenuItem key="logout"><i class="fas fa-sign-out-alt" />{{ $tl('logout') }}</NMenuItem>
          </NMenu>
        </template>
      </NDropdown>
    </div>
  </NLayoutHeader>
</template>

<script setup>
import { computed } from 'vue';
import { useDoc } from '@/hooks';
import ModalPassword from './ModalPassword.vue';
import ModalSettings from './ModalSettings.vue';
import { removeToken, isProxyMode } from '@/services';
import { useBootstrap, useModal, useRouter, usePanel } from '@admin/hooks';

const { createModal } = useModal();
const { routerTo } = useRouter();
const { createPanel, findPanel } = usePanel();
const { userProfile, userIt, userAgent, isSuperUser, isItUser } = useBootstrap();
const { adminDocUrl } = useDoc({ isItUser });

const settingBtnAttrs = computed(() => ({
  type: 'primary',
  shape: 'circle',
  class: `setting !border-current !bg-opacity-90 ${
    isSuperUser.value ? '!bg-yellow-600' : isItUser.value ? '!bg-gray-600' : '!bg-purple-600'
  }`,
  title: userProfile.value.fullName,
}));

const verifyBtnDisabled = computed(() => !!findPanel({ name: 'VerifyPanel' }));

const onCreateVerifyPanel = () => {
  const width = document.body.offsetWidth - 200;
  const height = document.body.offsetHeight - 200;
  createPanel({ name: 'VerifyPanel', width, height, left: 100, top: 70 });
};

const onSetting = $event => {
  const key = $event.key;
  if (key === 'password') openChangePassword();
  else if (key === 'settings') openSettings();
  else if (key === 'help') openOperationManual();
  else if (key === 'logout') doLogout();
};

const openChangePassword = () => {
  const model = {
    username: userProfile.value.username,
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  };
  return createModal(ModalPassword, { model, action: 'edit' });
};

const openSettings = () => createModal(ModalSettings, { model: {}, action: 'edit' });

const openOperationManual = () => window.open(adminDocUrl.value, '_blank');

const doLogout = () => {
  removeToken();
  routerTo('/login');
};
</script>

<style lang="less">
@import '/src/page-admin/style/config';

.n-layout-header {
  height: @head-height;
  line-height: @head-height;
  border-left: 1px solid #f0f2f5;
  border-bottom: 1px solid #dcdee2;
  padding: 0 @content-margin 0 20px;
  z-index: 100;

  .ant-breadcrumb {
    .breadcrumb-dashboard,
    .breadcrumb-level-3 {
      color: #f5f5f5;
    }

    .ant-breadcrumb-separator,
    .breadcrumb-level-1 {
      color: #eeeeee;
    }

    .breadcrumb-level-2 {
      color: @cyan-base;
    }
  }

  .right-text {
    .welcome,
    .it,
    .agent,
    .ant-divider-vertical {
      color: #eeeeee;
    }

    .user-name,
    .it-name,
    .agent-name {
      color: #f5f5f5;
    }

    button.setting-btn {
      border: 1px solid #ffffff;
    }
  }
}

.setting-popup > .ant-dropdown-content > ul.ant-dropdown-menu {
  li.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-divider) {
    min-width: 100px;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;

    i.fas {
      margin: auto 6px auto 0;
      width: 16px;
      height: 14px;
    }
  }
}
</style>
