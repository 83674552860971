<template>
  <NTabs
    :key="tabsKey"
    v-model:activeKey="activeKey"
    class="n-layout-tabs"
    type="editable-card"
    size="small"
    hide-add
    @change="onChange"
    @edit="onEdit"
  >
    <template #rightExtra>
      <NDropdown :trigger="['click']" placement="bottomRight" overlay-class-name="tabs-extra-popup">
        <NButton type="text" class="opacity-80">
          <template #icon><switcher-outlined /></template>
        </NButton>
        <template #overlay>
          <NMenu @click="onCloseOpt">
            <NMenuItem v-for="item in closeOpts" :key="item.key" :disabled="item.disabled">
              <close-square-outlined /><span class="ml-2 text-gray-600 text-xs">{{ $tl(item.key) }}</span>
            </NMenuItem>
          </NMenu>
        </template>
      </NDropdown>
    </template>
    <NTabPane v-for="tab in curTabs" :key="tab.name" :tab="$t(tab.name)" :closable="tab.path !== dashboard.path" />
  </NTabs>
</template>

<script>
import { last } from 'lodash-es';
import Sortable from 'sortablejs';
import { useTabs } from '@admin/hooks';
import dashboard from '@admin/menus/dashboard';
import { defineComponent, ref, watch, nextTick, computed } from 'vue';

export default defineComponent({
  setup() {
    const {
      tabsVisible,
      curTabs,
      curTab,
      setTabs,
      setTab,
      removeTab,
      closeCurrentTab,
      closeOtherTabs,
      closeRightTabs,
      closeAllTabs,
    } = useTabs();

    const tabsKey = ref(0);

    const activeKey = ref(curTab.value.name);

    let sortable;

    const closeOpts = computed(() => [
      { key: 'closeCurrentTab', disabled: curTab.value.name === dashboard.name },
      {
        key: 'closeOtherTabs',
        disabled: curTabs.value.length < 2 || (curTabs.value.length === 2 && curTab.value.name !== dashboard.name),
      },
      { key: 'closeRightTabs', disabled: last(curTabs.value)?.name === curTab.value.name },
      { key: 'closeAllTabs', disabled: curTabs.value.length === 1 },
    ]);

    const onChange = targetKey => {
      if (curTab.value.name !== targetKey) {
        setTab(targetKey);
        onDraggableInit();
      }
    };

    const onEdit = (targetKey, action) => {
      if (action === 'remove') removeTab(targetKey);
    };

    const onCloseOpt = $event => {
      const key = $event.key;
      if (key === 'closeCurrentTab') closeCurrentTab();
      else if (key === 'closeOtherTabs') closeOtherTabs();
      else if (key === 'closeRightTabs') closeRightTabs();
      else if (key === 'closeAllTabs') closeAllTabs();
    };

    const onDraggableInit = async () => {
      if (!tabsVisible.value) return;
      await nextTick();
      const tabsElement = document.querySelector('.n-layout-tabs .ant-tabs-nav-list');
      if (sortable) sortable.destroy();
      sortable = Sortable.create(tabsElement, {
        draggable: '.ant-tabs-tab-with-remove',
        onEnd: evt => {
          const { oldIndex, newIndex } = evt;
          if (oldIndex === newIndex) return;
          let sortedTabs = [dashboard];
          const tabPaneElements = tabsElement.getElementsByClassName('ant-tabs-tab-with-remove');
          Array.from(tabPaneElements)?.forEach(el => {
            const id = last(el?.children?.[0]?.id?.split('-'));
            sortedTabs = [...sortedTabs, curTabs.value.find(o => id === o.name)];
          });
          setTabs(sortedTabs);
        },
      });
    };

    watch(
      () => curTabs.value,
      () => {
        tabsKey.value += 1;
        onDraggableInit();
      },
      { immediate: true },
    );

    watch(
      () => curTab.value,
      to => (activeKey.value = to.name),
    );

    return { curTabs, curTab, tabsKey, activeKey, dashboard, closeOpts, onChange, onEdit, onCloseOpt };
  },
});
</script>

<style lang="less">
.n-layout-tabs {
  margin: 10px 16px 0 15px;
  width: calc(100% - 31px);
  height: 32px;

  .ant-tabs-tab {
    background-color: #f5f5f5 !important;
    border: 1px solid #e8eaec !important;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .ant-tabs-tab-active {
    font-weight: bold;
    background-color: #fff !important;
  }

  .ant-tabs-tab-btn:focus {
    color: unset;
  }
}
</style>
