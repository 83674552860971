<template>
  <NFormItem v-bind="formItemBaseAttrs" class="n-input-group">
    <div v-for="(text, index) in texts" :key="index" class="ant-input-group !mb-1">
      <input
        :id="`form_item_input_add_${index}`"
        type="text"
        :value="text"
        :title="value"
        class="ant-input !h-[32px]"
        @input="onChange($event, index)"
      />
      <span class="ant-input-group-addon">
        <NButton class="check-btn" :disabled="!text" @click="check(text, index)">
          <template #icon>
            <question-circle-outlined v-if="isUndefined(mValid[index])" :title="$tl('check')" />
            <check-circle-outlined v-else-if="mValid[index]" :title="$tl('validUrl')" class="n-success" />
            <close-circle-outlined v-else :title="$tl('invalidUrl')" class="n-error" />
          </template>
        </NButton>
      </span>
      <span class="ant-input-group-addon" :title="$tl('remove')">
        <NButton class="remove-btn" :disabled="texts.length < 2" @click="onRemove(index)">
          <template #icon><delete-outlined /></template>
        </NButton>
      </span>
    </div>
    <div class="add-btn" @click="onAdd"><plus-outlined /> {{ $tl('add') }}</div>
  </NFormItem>
</template>

<script>
import { ref, defineComponent, inject } from 'vue';
import { difference, isUndefined } from 'lodash-es';
import { useFormItemBaseAttrs } from '@/components/common/hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NInputGroup',

  props: {
    label: String,
    name: [String, Array],
    customLabel: String,
    value: String,
  },

  setup(props, { attrs, emit }) {
    const form = inject('NForm', null);

    const { formItemBaseAttrs } = useFormItemBaseAttrs(props);

    const texts = ref(props.value?.split(',') ?? []);

    const mValid = ref([]);

    texts.value.forEach((_, index) => (mValid.value[index] = undefined));

    const loading = ref(false);

    let { onCheck } = attrs;

    const onChange = (event, index) => {
      texts.value[index] = event.target.value;
      emitValue();
    };

    const onAdd = () => {
      texts.value.push('');
      mValid.value.push(undefined);
      emit('add');
      emitValue();
    };

    const check = (text, index) => {
      loading.value = true;
      return (async () => onCheck(text))()
        .then(r => (mValid.value[index] = r))
        .finally(() => (loading.value = false));
    };

    const onRemove = index => {
      texts.value.splice(index, 1);
      mValid.value.splice(index, 1);
      emit('remove');
      emitValue();
    };

    const emitValue = () => {
      const array = difference(texts.value, ['']); // 排除空输入项
      const v = array.join(',');
      if (v !== props.value) {
        emit('update:value', v);
        emit('change', v);
        form.exposed.validateFields([props.name]);
      }
    };

    return { formItemBaseAttrs, texts, mValid, isUndefined, onChange, onAdd, check, onRemove };
  },
});
</script>

<style lang="less">
.n-input-group {
  .ant-input-group-addon {
    margin: 0;
    padding: 0;
    border: 0;
    width: 37px;
    height: 32px;

    .check-btn,
    .remove-btn {
      margin: 0;
      padding: 0;
      width: 37px;
      height: 32px;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: #fafafa;
      border-color: #d9d9d9;
    }
  }

  .add-btn {
    height: 32px;
    padding: 5px 16px;
    background-color: var(--ant-primary-color);
    color: white;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      background: var(--ant-primary-5);
    }
  }
}
</style>
