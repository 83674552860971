<template>
  <NPanel :id="id" class="n-demo-panel" title="DemoPanel"> panel body </NPanel>
</template>

<script setup>
import { onUnmounted } from 'vue';
import { usePanel } from '@admin/hooks';

const props = defineProps({
  id: String,
  extra: [Object, Number, String, Array, Boolean],
});

const { closePanel } = usePanel();

onUnmounted(() => {
  closePanel(props.id);
});
</script>

<script>
export default {
  name: 'DemoPanel',
};
</script>
