<template>
  <NSelect class="n-it" v-bind="componentAttrs" />
</template>

<script>
import { useBootstrap } from '@admin/hooks';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'NIt',

  inheritAttrs: false,

  setup(props, { attrs, emit }) {
    const { userProfile, its, isItUser, isAgentUser } = useBootstrap();

    const componentAttrs = computed(() => {
      let value = attrs.value;
      if (isItUser.value || isAgentUser.value) {
        value = userProfile.value.itId;
        emit('update:value', value);
      }
      const label = 'it';
      const options = its.value.map(o => ({
        label: [null, ''].includes(o.name) ? `- (${o.value})` : `${o.name} (${o.value})`,
        value: o.value,
      }));
      const disabled = isItUser.value || isAgentUser.value ? true : attrs.disabled;
      return { ...attrs, value, label, options, disabled };
    });

    return { componentAttrs };
  },
});
</script>
