<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <AInput v-bind="inputAttrs" @change="onChange">
      <template #addonAfter>
        <BaseSelect v-bind="selectAttrs" @change="onChangeType" />
      </template>
    </AInput>
  </NFormItem>
</template>

<script>
import { useBootstrap } from '@admin/hooks';
import { inputableIdRegex } from '@/constant';
import { computed, defineComponent } from 'vue';
import { Input as AInput } from 'ant-design-vue/es';
import { useFormItemBaseAttrs } from '@/components/common/hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NDuration',

  components: { AInput },

  inheritAttrs: false,

  props: {
    label: String,
    name: [String, Array],
    customLabel: String,
  },

  setup(props, { attrs, emit }) {
    const { formItemBaseAttrs } = useFormItemBaseAttrs(props);

    const { ENUMS } = useBootstrap();

    const inputAttrs = computed(() => ({ ...attrs, value: attrs.value }));

    const selectAttrs = computed(() => {
      const value = attrs.type;
      const options = ENUMS.value.DurationType;
      const disabled = attrs.disabled ?? false;
      return { value, options, dropdownMatchSelectWidth: false, disabled };
    });

    const onChange = event => {
      const v = event?.target?.value ?? null;
      if ([undefined, null, NaN, ''].includes(v)) emit('update:value', null);
      else if (inputableIdRegex.test(v)) emit('update:value', v);
      else emit('update:value', attrs.value ?? null);
    };

    const onChangeType = v => emit('update:type', v);

    return { formItemBaseAttrs, inputAttrs, selectAttrs, onChange, onChangeType };
  },
});
</script>
