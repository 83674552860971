<template>
  <span :class="className">{{ label }}</span>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  template: Object,
  align: { type: String, default: 'left' },
  replacement: { type: String, default: '-' },
  showValue: Boolean,
});

const className = computed(() => `n-fx-rate-template-name n-span-ellipsis n-text-${props.align}`);

const label = computed(() => {
  return [undefined, null, {}].includes(props.template) ? '-' : `${props.template.name} (${props.template.id})`;
});
</script>
